import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { getGLTFModelByUZDZID } from '../api/upload'
import Button from '../components/buttons/Button'
import PoweredBy from '../components/footer/PoweredBy'
import ConvertAnother from '../components/svgs/ConvertAnother'
import DownloadFile from '../components/svgs/DownloadFile'
import UploadFile from '../components/svgs/UploadFile'
import ViewFile from '../components/svgs/ViewFile'
import SizedBox from '../components/ui/SizedBox'
import { IPageProps } from '../types/types'
import {
  A7ACenteredRow,
  BackgroundWrapper,
  Description,
  Heading,
  MainContainer,
} from '../utils/Containers'

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px dashed ${(props) => props.theme.colors.borderColor};
  border-radius: 20px;
  width: 100%;
  height: 84px;
  padding: 0px 30px;
  border-radius: 20px;
  ${(props) => props.theme.mediaQuery.fold} {
    padding: 0px 30px;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 0px 30px;
  }
`

const UploadedFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 0px;
  margin-top: 32px;
  background: none;
`

const UploadFileName = styled.h1`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.textColors[0]};
  max-width: 62px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 15px;

  ${(props) => props.theme.mediaQuery.fold} {
    margin-left: 10px;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 140px;
  }
`

const TooltipStatus = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.status === 'Converting'
      ? props.theme.colors.caution
      : props.theme.colors.success};
  color: white;
  padding: 4px 8px;
  width: fit-content;
  height: 18px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
`

const ThreeDBuilderIntermediate = ({ state, dispatch }: IPageProps) => {
  const navigate = useNavigate()
  let params = useParams()
  const [usdzIDLink, setUSDZIdLink] = useState('')
  const [loadingGLTFfile, setLoadingGLTFfile] = useState(false)

  const handleViewGLTFModel = async () => {
    if (state.gltf_id !== null) {
      navigate(`/view-model/${state.gltf_id}` , {state: {id: '3d-builder'}})
    } else {
      setLoadingGLTFfile(true)
      const res = await getGLTFModelByUZDZID(usdzIDLink)
      if (res && res.status === 200) {
        dispatch({
          type: 'ADD_GLTF_ID',
          payload: res.data.split('model/')[1],
        })
        let id = res.data.split('model/')[1]
        navigate(`/view-model/${id}`, { state: { id: '3d-builder' } })
        setLoadingGLTFfile(false)
      }
    }
  }

  useEffect(() => {
    document.title = 'xTransform - gLTF Model Viewer'
  }, [])

  useEffect(() => {
    if (params.id === null || params.id === undefined) navigate('/')
    try {
      const id = params.id as string
      setUSDZIdLink(id)
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  return (
    <>
      <BackgroundWrapper>
        <MainContainer>
          <Heading success={params.id !== null}>
            Your file has been generated!
          </Heading>
          <Description>
            Create a 3D model by uploading videos! <br />
          </Description>

          <UploadContainer>
            <UploadedFileContainer>
              <A7ACenteredRow>
                <UploadFile />
                <UploadFileName>model.USDZ</UploadFileName>
                <SizedBox height={0} width={10} />
                <TooltipStatus status={state.status}>
                  {state.status}
                </TooltipStatus>
              </A7ACenteredRow>
            </UploadedFileContainer>
          </UploadContainer>

          <>
            <SizedBox height={20} width={0} />
            <Button
              start={true}
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_THREED_BUILDER_URI}model/` +
                    usdzIDLink
                )
              }}
              height="60px"
              background={'#37BC8C'}
              fontSize="16px"
            >
              <DownloadFile />
              Download now!
            </Button>

            <SizedBox height={10} width={0} />
            <Button
              disabled={loadingGLTFfile}
              onClick={handleViewGLTFModel}
              background="linear-gradient(270deg, #47BEC9 0%, #6B6FD5 100%)"
            >
              {!loadingGLTFfile && <ViewFile />}
              {loadingGLTFfile ? 'Loading Model...' : 'View GLTF Model'}
            </Button>
            <SizedBox height={10} width={0} />

            <Button
              onClick={() => {
                dispatch({
                  type: 'REMOVE_FILE',
                })

                dispatch({
                  type: 'REMOVE_GLTF_ID',
                })

                dispatch({
                  type: 'REMOVE_USDZ_ID',
                })

                dispatch({
                  type: 'UPDATE_STATUS',
                  payload: 'Ready',
                })
                navigate('/3d-builder')
              }}
              textColor="#3E4453"
              background="#E1E6EF;"
            >
              <ConvertAnother />
              Build another model
            </Button>
            <SizedBox height={10} width={0} />
          </>
          <PoweredBy />
        </MainContainer>
      </BackgroundWrapper>
    </>
  )
}

export default ThreeDBuilderIntermediate
