import styled from 'styled-components'

const PoweredByContainer = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 66px;
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.textColors[1]};

  a {
    background: ${(props) => props.theme.colors.accent};
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-decoration: underline !important;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    margin-top: 90px;
  }
`
interface IPoweredbyProps {
  style?: React.CSSProperties
}
const PoweredBy = ({style}: IPoweredbyProps) => {
  return (
    <>
      <PoweredByContainer style={style}>
        Powered by{' '}
        <a href="https://a7alabs.com" target="_blank" rel="noopener noreferrer">
          A7A LABS
        </a>
      </PoweredByContainer>
    </>
  )
}

export default PoweredBy
