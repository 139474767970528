import styled from 'styled-components'

const AnimateWrapper = styled.div<{ start: boolean; animateProgress: number }>`
  left: 0;
  width: ${(props) =>
    props.start ? (props.animateProgress === 0 ? '90%' : '100%') : '0%'};
  height: 100%;
  border-radius: 10px;
  position: absolute;
  background-color: #37bc8c;
  transition: width 0.5s;
  ${(props) => props.start && `animation: progress 15s ease;`}
  @keyframes progress {
    0% {
      width: 0%;
    }
    100% {
      width: 90%;
    }
  }
`

interface AnimateProps {
  start: boolean
  animateProgress: number
}

const Animate = ({ start, animateProgress }: AnimateProps) => {
  return <AnimateWrapper start={start} animateProgress={animateProgress} />
}

export default Animate