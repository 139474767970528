import axios from 'axios';

// axios.defaults.baseURL = `${process.env.REACT_APP_BASE_URI}`;

let controller = new AbortController();
export const FORMClient = (baseURL: string) => axios.create({
  baseURL: baseURL,
  headers: {
    'content-type': 'multipart/form-data',
  },
  signal: controller.signal
});

export const cancelRequest = () => {
  controller.abort();
  console.log("EVERYTHING CANCELLED")
  // Reset 
  controller = new AbortController();
}
