import { DragEvent, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { cancelRequest } from '../api/bootstrap'
import { sendUSDZModel } from '../api/upload'
import Button from '../components/buttons/Button'
import PoweredBy from '../components/footer/PoweredBy'
import ConvertAnother from '../components/svgs/ConvertAnother'
import Cross from '../components/svgs/Cross'
import DownloadFile from '../components/svgs/DownloadFile'
import UploadFile from '../components/svgs/UploadFile'
import UploadIcon from '../components/svgs/UploadIcon'
import ValidateFile from '../components/svgs/ValidateFile'
import ViewFile from '../components/svgs/ViewFile'
import SizedBox from '../components/ui/SizedBox'
import UploadFileDescription from '../components/ui/UploadFileDescription'
import { IPageProps } from '../types/types'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  BackgroundWrapper,
  Description,
  Heading,
  MainContainer,
} from '../utils/Containers'
import { ValidateID } from '../utils/Validators'
import { useMediaQuery } from '../utils/useMediaQuery'
import ValidateGltf from '../utils/validateGltf'

const Label = styled(A7ACenteredColumn)`
  justify-content: flex-start;

  border: 1px dashed ${(props) => props.theme.colors.borderColor};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  height: 195px;

  h1 {
    margin: 36px 0px 30px 0px;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.textColors[0]};
    content: 'Upload';
  }
`
const UploadContainer = styled.div<{ id: any }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  border: 1px dashed ${(props) => props.theme.colors.borderColor};
  border-radius: 20px;

  width: 100%;

  height: ${(props) => (props.id === null ? '194px' : '84px')};

  padding: ${(props) => (props.id === null ? '0px 20px' : '0px 30px')};
  border-radius: 20px;

  ${(props) => props.theme.mediaQuery.fold} {
    padding: ${(props) => (props.id === null ? '0px 10px' : '0px 30px')};
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    padding: ${(props) => (props.id === null ? '0px 50px' : '0px 30px')};
  }
`

const UploadedFileContainer = styled.div<{ id: any }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 18px 20px;
  padding: ${(props) => (props.id === null ? '18px 20px' : '0px 0px')};
  margin-top: ${(props) => (props.id === null ? '70px' : '32px')};
  background: ${(props) =>
    props.id === null ? 'rgba(203, 208, 221, 0.2)' : 'none'};
`

const UploadFileName = styled.h1`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.textColors[0]};
  max-width: 62px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 15px;

  ${(props) => props.theme.mediaQuery.fold} {
    margin-left: 10px;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 140px;
  }
`

const FileSize = styled.span`
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  line-height: 15px;
  color: ${(props) => props.theme.colors.textColors[1]};
`

const TooltipStatus = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.status === 'Converting'
      ? props.theme.colors.caution
      : props.theme.colors.success};
  color: white;
  padding: 4px 8px;
  width: fit-content;
  height: 18px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
`
const Home = ({ state, dispatch }: IPageProps) => {
  useEffect(() => {
    document.title = 'xTransform - 3D Model Converter'
  }, [])

  const isMobile = useMediaQuery('(max-width: 767px)')
  const [isGltfValidateProcessing, setIsGltfValidateProcessing] =
    useState(false)
  const [isGltfValid, setIsGltfValid] = useState<boolean | null>(null)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      dispatch({
        type: 'ADD_FILE',
        payload: event.target.files[0],
      })
    } else return
  }

  const handleSubmit = async () => {
    dispatch({
      type: 'UPDATE_STATUS',
      payload: 'Converting',
    })

    const formData = new FormData()
    formData.append('file', state.file as Blob)
    let res = await sendUSDZModel(formData)
    if (res && res.status === 200) {
      if (!ValidateID(res.data)) {
        alert(
          'No faces Detected in the video. Please try again with a different video.'
        )
        dispatch({
          type: 'UPDATE_STATUS',
          payload: 'Ready',
        })
        dispatch({
          type: 'REMOVE_FILE',
        })
        return
      }

      dispatch({
        type: 'UPDATE_STATUS',
        payload: 'Finished',
      })
      dispatch({
        type: 'ADD_GLTF_ID',
        payload: res!.data,
      })
    }
  }

  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
    e.currentTarget.classList.add('drag-over')
  }

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.remove('drag-over')
    const file = e.dataTransfer.files[0]

    const extension = file.name.split('.').pop()
    if (extension !== 'usdz') {
      alert('File must have .usdz extension')
      return
    } else {
      dispatch({
        type: 'ADD_FILE',
        payload: file,
      })
    }
  }

  useEffect(() => {
    if (state.file) {
      if (state.file.size / 1024 / 1024 > 100) {
        // Greater than 100MB
        alert('File size must be less than 100MB')

        dispatch({
          type: 'REMOVE_FILE',
        })
      }
    }
  }, [dispatch, state.file])

  return (
    <>
      <BackgroundWrapper>
        <MainContainer>
          <Heading success={state.gltf_id !== null}>
            {state.gltf_id
              ? 'Your file has been converted!'
              : 'USDZ to GLTF Converter'}
          </Heading>
          <Description>
            This converter was built to make your life easier to convert USDZ
            file into GLTF file at a glance of time. Simply upload your file and
            press “Convert” button. Easy as that!
          </Description>

          {!state.file ? (
            <>
              <input
                type="file"
                id="usdzFile"
                hidden
                name="usdzFile"
                accept=".usdz"
                onChange={(e) => handleChange(e)}
              />
              <Label
                as="label"
                htmlFor="usdzFile"
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e)}
              >
                <SizedBox height={43} width={0} />
                <UploadIcon />
                <h1>
                  {isMobile ? 'Upload your file' : 'Drop or upload your file'}
                </h1>
                <UploadFileDescription
                  typeOfFile={'.USDZ extension accepted'}
                  maxLimit={'100MB'}
                />
              </Label>
            </>
          ) : (
            <UploadContainer id={state.gltf_id}>
              <UploadedFileContainer id={state.gltf_id}>
                <A7ACenteredRow>
                  <UploadFile />
                  <UploadFileName>
                    {state.file.name.split('').slice(0, -4).join('')}
                    {state.gltf_id === null ? 'USDZ' : 'gLTF'}
                  </UploadFileName>
                  <SizedBox height={0} width={10} />
                  <TooltipStatus status={state.status}>
                    {state.status}
                  </TooltipStatus>
                </A7ACenteredRow>
                <A7ACenteredRow>
                  <FileSize>
                    {(state.file.size / 1024 / 1025).toPrecision(3)} MB
                  </FileSize>
                  {!state.gltf_id && (
                    <>
                      <SizedBox height={0} width={isMobile ? 11 : 36} />
                      <Cross
                        onClick={() => {
                          cancelRequest()
                          dispatch({
                            type: 'REMOVE_FILE',
                          })
                          dispatch({
                            type: 'UPDATE_STATUS',
                            payload: 'Ready',
                          })
                        }}
                      />
                    </>
                  )}
                </A7ACenteredRow>
              </UploadedFileContainer>
              {!state.gltf_id && (
                <>
                  <SizedBox height={40} width={0} />
                  <UploadFileDescription
                    typeOfFile={'.USDZ extension accepted'}
                    maxLimit={'100MB'}
                  />
                </>
              )}
            </UploadContainer>
          )}
          <SizedBox height={20} width={0} />
          <Button
            start={true}
            animateProgress={state.gltf_id ? 1 : 0}
            onClick={async () => {
              state.gltf_id
                ? (window.location.href =
                    `${process.env.REACT_APP_MODEL_VIEWER_URI}` +
                    state.gltf_id!)
                : await handleSubmit()
            }}
            height="60px"
            disabled={!state.file}
            background={
              state.gltf_id
                ? '#37BC8C'
                : 'linear-gradient(270deg, #47BEC9 0%, #6B6FD5 100%)'
            }
            fontSize="16px"
          >
            {state.gltf_id && <DownloadFile />}
            {state.gltf_id ? 'Download now!' : 'Convert to gLTF'}
          </Button>

          {state.gltf_id && (
            <>
              <SizedBox height={10} width={0} />
              <Link to={`/view-model/${state.gltf_id}`} state={{id: 'home'}}>
                <Button
                  onClick={() => {}}
                  background="linear-gradient(270deg, #47BEC9 0%, #6B6FD5 100%)"
                >
                  <ViewFile />
                  View gLTF Model
                </Button>
              </Link>
              <SizedBox height={10} width={0} />
              <Button
                disabled={
                  isGltfValidateProcessing === true || isGltfValid === true
                }
                onClick={async () => {
                  if (!isGltfValid) {
                    setIsGltfValidateProcessing(() => true)
                    const res = await ValidateGltf(
                      `${process.env.REACT_APP_MODEL_VIEWER_URI}` +
                        state.gltf_id!
                    )
                    setIsGltfValid(() => res)
                    setIsGltfValidateProcessing(() => false)
                  }
                }}
                textColor="#3E4453"
                background="#E1E6EF;"
              >
                {!isGltfValidateProcessing && isGltfValid === false && (
                  <ValidateFile />
                )}

                {isGltfValidateProcessing ? (
                  'Validating...'
                ) : (
                  <>
                    {isGltfValid === null
                      ? 'Validate gLTF'
                      : isGltfValid
                      ? 'Gltf is valid'
                      : 'Gltf is not valid'}
                  </>
                )}
              </Button>

              <SizedBox height={10} width={0} />

              <Button
                onClick={() => {
                  dispatch({
                    type: 'REMOVE_FILE',
                  })

                  dispatch({
                    type: 'REMOVE_GLTF_ID',
                  })

                  dispatch({
                    type: 'UPDATE_STATUS',
                    payload: 'Ready',
                  })
                }}
                textColor="#3E4453"
                background="#E1E6EF;"
              >
                <ConvertAnother />
                Convert another file
              </Button>
              <SizedBox height={10} width={0} />
            </>
          )}
          <PoweredBy />
        </MainContainer>
      </BackgroundWrapper>
    </>
  )
}

export default Home
