import styled from "styled-components"
import { A7ACenteredRow } from "../../utils/Containers"

const UploadDescriptionContainer = styled(A7ACenteredRow)`
  gap: 11px;
  margin-bottom: 15px;

  span {
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.colors.textColors[1]};
  }

  .dot {
    width: 5px;
    height: 5px;
    background: #cbd0dd;
    border-radius: 50%;
  }
`
interface IUploadFileDescriptionProps {
  typeOfFile: string
  maxLimit: string
}
const UploadFileDescription = ({typeOfFile, maxLimit}: IUploadFileDescriptionProps) => {
  return (
    <>
      <UploadDescriptionContainer>
        <span>{typeOfFile}</span>
        <span className="dot" />
        <span>{maxLimit}</span>
      </UploadDescriptionContainer>
    </>
  )
}

export default UploadFileDescription
