const ValidateFile = () => {
  return (
    <>
      <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.394 10.394L7.6062 7.6062M0.606201 4.68953C0.606201 5.22577 0.71182 5.75675 0.917026 6.25216C1.12223 6.74757 1.42301 7.19771 1.80218 7.57689C2.18135 7.95606 2.6315 8.25684 3.12691 8.46204C3.62232 8.66725 4.1533 8.77287 4.68953 8.77287C5.22577 8.77287 5.75675 8.66725 6.25216 8.46204C6.74757 8.25684 7.19771 7.95606 7.57689 7.57689C7.95606 7.19771 8.25684 6.74757 8.46204 6.25216C8.66725 5.75675 8.77287 5.22577 8.77287 4.68953C8.77287 4.1533 8.66725 3.62232 8.46204 3.12691C8.25684 2.6315 7.95606 2.18135 7.57689 1.80218C7.19771 1.42301 6.74757 1.12223 6.25216 0.917026C5.75675 0.71182 5.22577 0.606201 4.68953 0.606201C4.1533 0.606201 3.62232 0.71182 3.12691 0.917026C2.6315 1.12223 2.18135 1.42301 1.80218 1.80218C1.42301 2.18135 1.12223 2.6315 0.917026 3.12691C0.71182 3.62232 0.606201 4.1533 0.606201 4.68953Z" stroke="#3E4454" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M3.13696 4.62923L4.16366 5.65592L6.21704 3.60254" stroke="#3E4454" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

    </>
  )
}

export default ValidateFile