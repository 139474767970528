import { validateBytes } from 'gltf-validator'

const ValidateGltf = async (url) => {
  let result = null
  
  try {
    const response = await fetch(url)
    const asset = await response.arrayBuffer()
    await validateBytes(new Uint8Array(asset))
    console.log(validateBytes)
    result = true
  } catch (error) {
    result = false
  }

  return result
}

export default ValidateGltf