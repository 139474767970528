import styled from 'styled-components'
import BackgroundImage from '../assets/Webby.svg'
import { motion } from 'framer-motion'

export const A7ARow = styled.div`
  display: flex;
  flex-direction: row;
`

export const A7AXCenteredRow = styled(A7ARow)`
  justify-content: center;
`

export const A7AYCenteredRow = styled(A7ARow)`
  align-items: center;
`

export const A7ACenteredRow = styled(A7ARow)`
  justify-content: center;
  align-items: center;
`

export const A7AColumn = styled.div`
  display: flex;
  flex-direction: column;
`

export const A7AXCenteredColumn = styled(A7AColumn)`
  justify-content: center;
`

export const A7AYCenteredColumn = styled(A7AColumn)`
  align-items: center;
`

export const A7ACenteredColumn = styled(A7AColumn)`
  justify-content: center;
  align-items: center;
`

export const A7AXSpaceBetweenRow = styled(A7ARow)`
  justify-content: space-between;
`

export const A7AYSpaceBetweenRow = styled(A7AColumn)`
  align-items: center;
`

export const MainContainer = styled(motion.div).attrs({
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: {
    opacity: 0,
  },
  transition: { duration: 0.5 },
})`
  /* min-width: auto; */
  width: calc(100% - 40px);
  /* margin: 0px 20px; */
  background: ${(props) => props.theme.colors.background};
  box-shadow: 0px 0px 150px rgba(71, 75, 130, 0.1);
  border-radius: 20px;
  padding: 20px;
  max-width: 622px;

  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 50px 50px 30px 50px;
    height: auto;
    width: 100%;
    /* height: 625px; */
    margin: 0px;
  }

  ${(props) => props.theme.mediaQuery.fold} {
    margin: 0px 10px;
  }
`

export const BackgroundWrapper = styled(A7ACenteredColumn)`
  min-height: 100vh;
  width: 100%;
  background: url(${BackgroundImage});
  background-size: cover;
  background-position: center center;
`
export const Heading = styled.h1<{ success?: boolean }>`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  background: ${(props) =>
    props.success ? props.theme.colors.success : props.theme.colors.accent};
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 20px;

  ${(props) => props.theme.mediaQuery.tablet} {
    font-weight: 600;
    font-size: 36px;
    line-height: 46px;
  }
`
export const Description = styled.p`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  text-align: center;
  color: ${(props) => props.theme.colors.textColors[1]};

  margin-bottom: 30px;

  ${(props) => props.theme.mediaQuery.tablet} {
    margin-bottom: 70px;
  }
`
