import { SVGProps } from 'react'

const DownloadFile = (props: SVGProps<SVGSVGElement>) => {
  return (
    <>
      <svg
        width={19}
        height={19}
        fill="none"
        viewBox='0 0 19 19'
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M1.5 14v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-2m-13-6 5 5m0 0 5-5m-5 5V1"
          stroke="#fff"
        />
      </svg>
    </>
  )
}

export default DownloadFile
