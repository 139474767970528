const UploadFile = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14 3V7C14 7.26522 14.1054 7.51957 14.2929 7.70711C14.4804 7.89464 14.7348 8 15 8H19"
          stroke="url(#paint0_linear_12_583)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7 21H17C17.5304 21 18.0391 20.7893 18.4142 20.4142C18.7893 20.0391 19 19.5304 19 19V8L14 3H7C6.46957 3 5.96086 3.21071 5.58579 3.58579C5.21071 3.96086 5 4.46957 5 5V19C5 19.5304 5.21071 20.0391 5.58579 20.4142C5.96086 20.7893 6.46957 21 7 21Z"
          stroke="url(#paint1_linear_12_583)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.49841 16.3348L8.66455 15.8761V14.8338M8.66455 13.1661V12.1237M8.66455 12.1237L9.49841 11.6651M8.66455 12.1237L9.49841 12.5824M11.1661 10.7062L12 10.2476L12.8339 10.7062M14.5016 11.6651L15.3354 12.1237M15.3354 12.1237V13.1661M15.3354 12.1237L14.5016 12.5824M15.3354 14.8338V15.8761L14.5016 16.3431M12.8339 17.2937L12 17.7523M12 17.7523L11.1661 17.2937M12 17.7523V16.71M12 13.9999L12.8339 13.5413M12 13.9999V15.0423M12 13.9999L11.1661 13.533"
          stroke="url(#paint2_linear_12_583)"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_12_583"
            x1="19"
            y1="5.50005"
            x2="14"
            y2="5.50005"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#47BEC9" />
            <stop offset="1" stopColor="#6B6FD5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_12_583"
            x1="19"
            y1="12.0002"
            x2="5"
            y2="12.0002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#47BEC9" />
            <stop offset="1" stopColor="#6B6FD5" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_12_583"
            x1="15.3354"
            y1="14"
            x2="8.66455"
            y2="14"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#47BEC9" />
            <stop offset="1" stopColor="#6B6FD5" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default UploadFile
