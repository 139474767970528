import styled from "styled-components";

const ViewerContainer = styled.div`
  height: 300px;
  width: 100%;
  min-width: 240px;
  ${(props) => props.theme.mediaQuery.fold} {
    width: 250px;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    /* width: 364px; */
    height: 364.34px;
  }
`;

interface IViewer {
  modelLink: string;
}
const ModelViewer = ({ modelLink }: IViewer) => {
  return (
    <>
      <ViewerContainer>
        <model-viewer
          alt="Neil Armstrong's Spacesuit from the Smithsonian Digitization Programs Office and National Air and Space Museum"
          src={modelLink}
          seamless-poster
          ar={true}
          ar-modes="webxr scene-viewer quick-look"
          shadow-intensity="1"
          camera-controls
          enable-pan
          style={{ width: "100%", height: "100%" }}
        />
      </ViewerContainer>
    </>
  );
};

export default ModelViewer;
