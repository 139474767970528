import { FORMClient } from "./bootstrap"; 

export const sendUSDZModel = async (data: FormData) => {
  const url = `${process.env.REACT_APP_BASE_URI}`;
  try {
      const res = await FORMClient(url).post('convert' , data );
      return res;
  } catch (e) {
      console.error(e);
  }
}

export const sendVideo = async (data: FormData) => {
  const url = `${process.env.REACT_APP_THREED_BUILDER_URI}`;
  try {
    const res = await FORMClient(url).post('build-model', data);
      return res;
  } catch (e) {
      console.error(e);
  }
}

export const getGLTFModelByUZDZID = async (id: string) => {
  console.log(id);
  const url = `${process.env.REACT_APP_THREED_BUILDER_URI}`;
  try {
      const res = await FORMClient(url).get(`make-gltf/${id}`);
      return res;
  } catch (e) {
      console.error(e);
  }
}