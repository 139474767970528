const ConvertAnother = () => {
  return (
    <>
      <svg
        width="11"
        height="11"
        viewBox="0 0 11 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.1666 4.91666C10.0239 3.89012 9.54771 2.93896 8.81129 2.2097C8.07487 1.48044 7.11911 1.01353 6.09123 0.880897C5.06334 0.748267 4.02036 0.957272 3.12295 1.47572C2.22554 1.99416 1.52348 2.79329 1.12492 3.74999M0.833252 1.41666V3.74999H3.16659M0.833252 6.08332C0.975912 7.10986 1.45213 8.06102 2.18855 8.79029C2.92496 9.51955 3.88073 9.98646 4.90861 10.1191C5.93649 10.2517 6.97947 10.0427 7.87688 9.52426C8.7743 9.00582 9.47636 8.2067 9.87492 7.24999M10.1666 9.58332V7.24999H7.83325"
          stroke="#3E4454"
          strokeWidth="0.875"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  )
}

export default ConvertAnother
