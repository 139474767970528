import { ThemeWeb } from "styled-components";

const theme: ThemeWeb = {
  colors: {
    background: "#e7ebf3",
    accent: "linear-gradient(270deg, #47BEC9 0%, #6B6FD5 100%)",
    borderColor: "#cbd0dd",
    success: "#37bc8c",
    caution: "#EDB23F",
    textColors: ["#3E4454", "#747985"],
  },
  mediaQuery: {
    fold: "@media (max-width: 365px)",
    tablet: "@media (min-width: 767px)",
    desktop: "@media (min-width: 1200px)",
  },
};
export { theme };