import { SVGProps } from 'react'

interface CrossProps {
  onClick?: () => void
  props?: SVGProps<SVGSVGElement>
  style?: React.CSSProperties
}
const Cross = ({ onClick , props , style }: CrossProps) => {
  return (
    <button
      style={{
        cursor: 'pointer',
        border: 'transparent',
        outline: 'none',
        background: 'transparent',
        marginTop: 3,
        padding: 0,
        ...style,
      }}
      onClick={onClick}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
      >
        <path
          d="M13 1L1 13M1 1L13 13"
          stroke="#747985"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </button>
  )
}

export default Cross
