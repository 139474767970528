const UploadIcon = () => {
  return (
    <>
      <svg
        width="56"
        height="46"
        viewBox="0 0 56 46"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M13.6667 36.4999C10.3394 36.4999 7.14842 35.2357 4.79569 32.9852C2.44297 30.7348 1.12122 27.6825 1.12122 24.5C1.12122 21.3174 2.44297 18.2651 4.79569 16.0147C7.14842 13.7642 10.3394 12.5 13.6667 12.5C14.4525 8.99909 16.7514 5.92257 20.0576 3.94719C21.6946 2.96908 23.5297 2.29075 25.4581 1.95092C27.3864 1.61109 29.3702 1.61643 31.2963 1.96662C33.2224 2.31681 35.0529 3.005 36.6834 3.9919C38.314 4.97879 39.7126 6.24507 40.7994 7.71843C41.8862 9.19178 42.6399 10.8434 43.0174 12.5789C43.395 14.3144 43.3891 16.0998 43 17.8333H45.6667C48.142 17.8333 50.516 18.8166 52.2663 20.567C54.0167 22.3173 55 24.6913 55 27.1666C55 29.642 54.0167 32.0159 52.2663 33.7663C50.516 35.5166 48.142 36.4999 45.6667 36.4999H43"
          stroke="url(#paint0_linear_2_127)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M19 28.5L27 20.5M27 20.5L35 28.5M27 20.5V44.5"
          stroke="url(#paint1_linear_2_127)"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <defs>
          <linearGradient
            id="paint0_linear_2_127"
            x1="55"
            y1="19.1003"
            x2="1.12122"
            y2="19.1003"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#47BEC9" />
            <stop offset="1" stopColor="#6B6FD5" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_2_127"
            x1="35"
            y1="32.5002"
            x2="19"
            y2="32.5002"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#47BEC9" />
            <stop offset="1" stopColor="#6B6FD5" />
          </linearGradient>
        </defs>
      </svg>
    </>
  )
}

export default UploadIcon
