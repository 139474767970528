import { DragEvent, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { cancelRequest } from '../api/bootstrap'
import { sendVideo } from '../api/upload'
import Button from '../components/buttons/Button'
import PoweredBy from '../components/footer/PoweredBy'
import Cross from '../components/svgs/Cross'
import UploadFile from '../components/svgs/UploadFile'
import UploadIcon from '../components/svgs/UploadIcon'
import SizedBox from '../components/ui/SizedBox'
import UploadFileDescription from '../components/ui/UploadFileDescription'
import { IPageProps } from '../types/types'
import {
  A7ACenteredColumn,
  A7ACenteredRow,
  BackgroundWrapper,
  Description,
  Heading,
  MainContainer,
} from '../utils/Containers'
import { ValidateID } from '../utils/Validators'
import { useMediaQuery } from '../utils/useMediaQuery'

const Label = styled(A7ACenteredColumn)`
  justify-content: flex-start;

  border: 1px dashed ${(props) => props.theme.colors.borderColor};
  border-radius: 20px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 20px;
  width: 100%;
  height: 195px;

  h1 {
    margin: 36px 0px 30px 0px;
    font-family: 'Dosis';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: ${(props) => props.theme.colors.textColors[0]};
    content: 'Upload';
  }
`
const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  border: 1px dashed ${(props) => props.theme.colors.borderColor};
  border-radius: 20px;
  width: 100%;
  height: 194px;
  padding: 0px 20px;
  border-radius: 20px;
  ${(props) => props.theme.mediaQuery.fold} {
    padding: 0px 10px;
  }
  ${(props) => props.theme.mediaQuery.tablet} {
    padding: 0px 50px;
  }
`

const UploadedFileContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  padding: 18px 20px;
  margin-top: 70px;
  background: rgba(203, 208, 221, 0.2);
`

const UploadFileName = styled.h1`
  font-family: 'Dosis';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: ${(props) => props.theme.colors.textColors[0]};
  max-width: 62px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  margin-left: 15px;

  ${(props) => props.theme.mediaQuery.fold} {
    margin-left: 10px;
  }

  ${(props) => props.theme.mediaQuery.tablet} {
    max-width: 140px;
  }
`

const FileSize = styled.span`
  font-weight: 400;
  font-size: 12px;
  white-space: nowrap;
  line-height: 15px;
  color: ${(props) => props.theme.colors.textColors[1]};
`

const TooltipStatus = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(props) =>
    props.status === 'Converting'
      ? props.theme.colors.caution
      : props.theme.colors.success};
  color: white;
  padding: 4px 8px;
  width: fit-content;
  height: 18px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #ffffff;
`

const ThreeDBuilder = ({ state, dispatch }: IPageProps) => {
  const navigate = useNavigate()

  useEffect(() => {
    document.title = 'xTransform - 3D Builder'
  }, [])

  const isMobile = useMediaQuery('(max-width: 767px)')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files !== null) {
      dispatch({
        type: 'ADD_FILE',
        payload: event.target.files[0],
      })
    } else return
  }

  const handleSubmit = async () => {
    dispatch({
      type: 'UPDATE_STATUS',
      payload: 'Converting',
    })

    const formData = new FormData()
    formData.append('file', state.file as File)
    let res = await sendVideo(formData)

    if (res && res.status === 200) {
      if (!ValidateID(res.data)) {
        alert(
          'No Object Detected in the video. Please try again with a different video.'
        )
        dispatch({
          type: 'UPDATE_STATUS',
          payload: 'Ready',
        })
        dispatch({
          type: 'REMOVE_FILE',
        })
        return
      }
      dispatch({
        type: 'UPDATE_STATUS',
        payload: 'Finished',
      })
      dispatch({
        type: 'ADD_USDZ_ID',
        payload: res!.data,
      })
    }
    navigate('/3d-builder/' + res!.data)
  }

  const handleDragOver = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    e.stopPropagation()
    e.dataTransfer.dropEffect = 'copy'
    e.currentTarget.classList.add('drag-over')
  }

  const handleDrop = (e: DragEvent<HTMLLabelElement>) => {
    e.preventDefault()
    e.stopPropagation()
    e.currentTarget.classList.remove('drag-over')
    const file = e.dataTransfer.files[0]

    const extension = file.name.split('.').pop()
    if (extension !== 'mp4' && extension !== 'mov') {
      alert('File must have .mp4 or .mov extension')
      return
    } else {
      dispatch({
        type: 'ADD_FILE',
        payload: file,
      })
    }
  }

  useEffect(() => {
    if (state.file) {
      if (state.file.size / 1024 / 1024 / 1024 > 1) {
        // Greater than 1GB
        alert('File size must be less or equal to 1GB')
        dispatch({
          type: 'REMOVE_FILE',
        })
      }
    }
  }, [dispatch, state.file])

  return (
    <>
      <BackgroundWrapper>
        <MainContainer>
          <Heading success={state.usdz_id !== null}>
            {state.usdz_id ? 'Your file has been generated!' : '3D builder'}
          </Heading>
          <Description>Create a 3D model by uploading videos!</Description>

          {!state.file ? (
            <>
              <input
                type="file"
                id="video"
                hidden
                name="video"
                accept=".mp4 , .mov"
                multiple
                onChange={(e) => handleChange(e)}
              />
              <Label
                as="label"
                htmlFor="video"
                onDragOver={(e) => handleDragOver(e)}
                onDrop={(e) => handleDrop(e)}
              >
                <SizedBox height={43} width={0} />
                <UploadIcon />
                <h1>
                  {isMobile ? 'Upload your files' : 'Drop or upload your files'}
                </h1>
                <UploadFileDescription
                  typeOfFile=".mp4 or .mov"
                  maxLimit="1 GB"
                />
              </Label>
            </>
          ) : (
            <UploadContainer>
              <UploadedFileContainer>
                <A7ACenteredRow>
                  <UploadFile />
                  <UploadFileName>{state.file.name}</UploadFileName>
                  <SizedBox height={0} width={10} />
                  <TooltipStatus status={state.status}>
                    {state.status}
                  </TooltipStatus>
                </A7ACenteredRow>
                <A7ACenteredRow>
                  <FileSize>
                    {(state.file.size / 1024 / 1024).toPrecision(3)} MB
                  </FileSize>
                  <SizedBox height={0} width={isMobile ? 11 : 36} />
                  <Cross
                    onClick={() => {
                      cancelRequest()
                      dispatch({
                        type: 'REMOVE_FILE',
                      })
                      dispatch({
                        type: 'UPDATE_STATUS',
                        payload: 'Ready',
                      })
                    }}
                  />
                </A7ACenteredRow>
              </UploadedFileContainer>
              <SizedBox height={40} width={0} />
              <UploadFileDescription
                typeOfFile=".mp4 or .mov"
                maxLimit="1 GB"
              />
            </UploadContainer>
          )}
          <SizedBox height={20} width={0} />
          <Button
            start={true}
            animateProgress={state.usdz_id ? 1 : 0} // if this is removed then the progress bar will not animate
            onClick={handleSubmit}
            height="60px"
            disabled={!state.file}
            fontSize="16px"
          >
            Create Model
          </Button>
          <PoweredBy />
        </MainContainer>
      </BackgroundWrapper>
    </>
  )
}

export default ThreeDBuilder
