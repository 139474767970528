/* eslint-disable no-useless-escape */
import { useEffect, useReducer } from "react"
import { RouterProvider, createBrowserRouter } from "react-router-dom"
import Home from "./pages/Home"
import ThreeDBuilder from "./pages/ThreeDBuilder"
import ThreeDBuilderIntermediate from "./pages/ThreeDBuilderIntermediate"
import ViewModel from "./pages/ViewModel"
import { Action, IStore } from "./types/types"

const App = () => {
  const store: IStore = {
    file: null,
    gltf_id: null,
    usdz_id: null,
    status: "Ready",
  }

  const reducer = (state: IStore, action: Action): IStore => {
    switch (action.type) {
      case "ADD_FILE":
        return {
          ...state,
          file: action.payload,
        }
      case "ADD_GLTF_ID":
        return {
          ...state,
          gltf_id: action.payload,
        }
      case "ADD_USDZ_ID":
        return {
          ...state,
          usdz_id: action.payload,
        }
      case "REMOVE_FILE":
        return {
          ...state,
          file: null,
        }
      case "REMOVE_GLTF_ID":
        return {
          ...state,
          gltf_id: null,
        }
      case "REMOVE_USDZ_ID":
        return {
          ...state,
          usdz_id: null,
        }
      case "UPDATE_STATUS":
        return {
          ...state,
          status: action.payload,
        }
      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, store)

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home state={state} dispatch={dispatch} />,
    },
    {
      path: "/view-model/:id",
      element: <ViewModel />,
    },
    {
      path: "/3d-builder",
      element: <ThreeDBuilder state={state} dispatch={dispatch} />,
    },
    {
      path: "/3d-builder/:id",
      element: <ThreeDBuilderIntermediate state={state} dispatch={dispatch} />,
    },
  ])

  useEffect(() => {
    console.log(
      `%c
      ______   ________  ______         __                  __                 
      /      \ /        |/      \       /  |                /  |                
     /$$$$$$  |$$$$$$$$//$$$$$$  |      $$ |        ______  $$ |____    _______ 
     $$ |__$$ |    /$$/ $$ |__$$ |      $$ |       /      \ $$      \  /       |
     $$    $$ |   /$$/  $$    $$ |      $$ |       $$$$$$  |$$$$$$$  |/$$$$$$$/ 
     $$$$$$$$ |  /$$/   $$$$$$$$ |      $$ |       /    $$ |$$ |  $$ |$$      \ 
     $$ |  $$ | /$$/    $$ |  $$ |      $$ |_____ /$$$$$$$ |$$ |__$$ | $$$$$$  |
     $$ |  $$ |/$$/     $$ |  $$ |      $$       |$$    $$ |$$    $$/ /     $$/ 
     $$/   $$/ $$/      $$/   $$/       $$$$$$$$/  $$$$$$$/ $$$$$$$/  $$$$$$$/  
    `,
      `color:#47BEC9`
    )
  }, [])

  return (
    <>
      <RouterProvider router={router} />
    </>
  )
}

export default App
