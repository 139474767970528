import { useEffect, useState } from 'react'
import styled from 'styled-components'
import Animate from './Animate'
import HighlightShine from './HighlightShine'

const GenericButton = styled.button<{
  background?: string
  height?: string
  borderRadius?: string
  disabled?: boolean
  fontSize?: string
  textColor?: string
}>`
  position: relative;
  background: ${(props) =>
    props.disabled
      ? '#E1E6EF'
      : props.background
      ? props.background
      : props.theme.colors.accent};
  color: ${(props) =>
    props.disabled ? '#CBD0DD' : props.textColor ? props.textColor : '#fff'};
  border: none;
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : '10px'};
  height: ${(props) => (props.height ? props.height : '35px')};
  gap: 10px;
  display: flex;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : '12px')};
  span {
    display: flex;
    align-items: center;
    gap: 10px;
    z-index: 2;
  }
`

interface IButtonProps {
  onClick: (e: React.MouseEvent<HTMLElement>) => void
  children: React.ReactNode
  background?: string
  height?: string
  borderRadius?: string
  disabled?: boolean
  fontSize?: string
  textColor?: string
  start?: boolean
  animateProgress?: number
}
const Button = ({
  onClick,
  children,
  background,
  height,
  disabled,
  fontSize,
  textColor,
  borderRadius,
  start,
  animateProgress,
}: IButtonProps) => {
  const [startAnimation, setStartAnimation] = useState(false)

  // Reset animation when progress is 100% or if it is disabled
  useEffect(() => {
    if (animateProgress === 1 || disabled === true) {
      setStartAnimation(false)
    }
  }, [animateProgress , disabled])

  const handleClickEvent = (e: React.MouseEvent<HTMLElement>) => {
    onClick(e)
    if (start && animateProgress === 0) {
      setStartAnimation(true)
    }
  }
  return (
    <>
      <GenericButton
        onClick={disabled ? () => {} : handleClickEvent}
        height={height}
        disabled={disabled}
        background={background}
        borderRadius={borderRadius}
        fontSize={fontSize}
        textColor={textColor}
      >
        {startAnimation && animateProgress !== undefined && !disabled && (
          <Animate start={startAnimation} animateProgress={animateProgress} /> 
        )}
        {!disabled && <HighlightShine/>}
        <span>{children}</span>
      </GenericButton>
    </>
  )
}

export default Button
