import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import {
  A7ARow,
  BackgroundWrapper,
  Heading,
  MainContainer,
} from '../utils/Containers'
import Button from '../components/buttons/Button'
import DownloadFile from '../components/svgs/DownloadFile'
import SizedBox from '../components/ui/SizedBox'
import { useMediaQuery } from '../utils/useMediaQuery'
import PoweredBy from '../components/footer/PoweredBy'
import styled from 'styled-components'
import Cross from '../components/svgs/Cross'
import { useNavigate } from 'react-router-dom'
import ModelViewer from '../components/Viewer'
// import { useLocation } from 'react-router-dom'

const HeadingContainer = styled(A7ARow)`
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
`

const ViewModel = () => {
  useEffect(() => {
    document.title = 'xTransform - gLTF Model Viewer'
  }, [])

  let params = useParams()
  const location = useLocation()
  const isMobile = useMediaQuery('(max-width: 767px)')
  const [modelLink, setModelLink] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    if (params.id === null || params.id === undefined) {
      navigate('/')
    }
    try {
      const gltfLink = `${process.env.REACT_APP_MODEL_VIEWER_URI}` + params.id
      setModelLink(gltfLink)
    } catch (e) {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.id])

  const handlePreviousRoute = (id: string | undefined | null) => {
    if (id === null || id === undefined) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }

  return (
    <>
      <BackgroundWrapper>
        <MainContainer>
          <HeadingContainer>
            <Cross style={{ marginBottom: 15 }} onClick={() => navigate(-1)} />
            <Heading>View gltf model</Heading>
            <div />
          </HeadingContainer>
          <SizedBox height={30} width={0} />
          <ModelViewer modelLink={modelLink} />
          <SizedBox height={isMobile ? 20 : 30} width={0} />
          <a href={modelLink} download>
            <Button
              onClick={() => {}}
              height="35px"
              borderRadius="5px"
              background={'#37BC8C'}
              fontSize="12px"
            >
              <DownloadFile height={14} width={14} />
              Download gLTF file
            </Button>
          </a>
          <SizedBox height={10} width={0} />
          <Button
            onClick={() => {
              handlePreviousRoute(location.state?.id)
            }}
            textColor="#3E4453"
            background="#E1E6EF;"
          >
            Go home
          </Button>
          <PoweredBy style={{ marginTop: 30 }} />
        </MainContainer>
      </BackgroundWrapper>
    </>
  )
}

export default ViewModel
